.table-dark {
    --bs-table-bg: #000000;
    --bs-table-striped-bg: #ff6e0e;
}

:root{
    --brax-primary: #000000;
    --brax-secondary: #ff6e0e;
}

.btn-brax {
    color: #fff;
    background-color: var(--brax-secondary);
    border-color: var(--brax-secondary);
}

.form-select{
    border: 2.5px solid var(--brax-secondary);
    border-radius: 10px;
    &:focus{
        border: 2.5px solid var(--brax-secondary);
        box-shadow: 0 0 0 0.25rem rgb(255 110 14 / 25%);
        border-radius: 10px;
    }
}

.lupa{
    height: 0px;
    margin-top: 35px;
    margin-left: 0px;
    padding-left: 0px;
}

#nprogress .bar{
    height: 5px !important;
}